import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  ButtonGroup,
  Typography,
  Box
} from '@mui/material';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import moment from 'moment';
import Leads from '../LeadsContainer/LeadsFilter';
import { LeadTypeEnum } from '../../shared/Constants';
import { DealerContext } from '../../shared/DealerContext';
import { LoadingContext } from '../../shared/context/loadingContext';
import { AuthContext } from '../../shared/context/AuthContext';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const Root = styled('div')(({ theme }) => ({
  marginTop: '2rem',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  position: 'relative'
}));

const Section = styled('div')(({ theme }) => ({
  width: '48%',
  height: '400px',
  marginBottom: '1rem',
  padding: '1rem',
  boxShadow: theme.shadows[1],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

const PieChartContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '400px',
  width: '100%'
});

const PieChart = styled('div')({
  flex: 3,
  height: '100%',
  position: 'relative'
});

const DropdownContainer = styled('div')({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: '1rem',
  height: '100%'
});

const TotalLeadsBox = styled(Box)(({ theme }) => ({
  margin: '1rem',
  padding: '1rem',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: '8px',
  boxShadow: theme.shadows[3],
  marginLeft: 'auto'
}));

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true
    }
  },
  animation: {
    duration: 2000,
    easing: 'easeInOutBounce'
  },
  hover: {
    animationDuration: 1000,
    mode: 'nearest',
    intersect: true
  }
};

const pieOptions = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    animateScale: true,
    animateRotate: true
  }
};

// Styled components for table row
const StyledTableRow = styled(TableRow)(({ theme, index }) => ({
  backgroundColor: index % 2 === 0 ? theme.palette.action.hover : 'transparent'
}));

export default function Dashboard() {
  const [leadTypes, setLeadTypes] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState('today');
  const [formattedDate, setFormattedDate] = useState(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
  const { dealerId, selectedMotorGroup } = useContext(DealerContext);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const {  userSession } = useContext(AuthContext);
  const websiteSource = 1;

  const { loading, ranges, total } = Leads(
    null,
    null,
    null,
    null,
    -1,
    formattedDate,
    null,
    null,
    null,
    selectedMotorGroup.motorgroupID,
    dealerId,
    '',
    true
  );

  useEffect(() => {
    getLeadTypes();
  }, []);

  useEffect(() => {
    dealerId && (loading ? showLoading() : hideLoading());
  }, [loading, dealerId, showLoading, hideLoading]);

  function getLeadTypes() {
    const allLeadTypes = [...Object.entries(LeadTypeEnum).map(([key, value]) => ({ id: value, name: key }))];
    setLeadTypes(allLeadTypes);
  }

  function isToday(date) {
    return moment(date).isSame(new Date(), 'day');
  }

  function isThisMonth(date) {
    return moment(date).isSame(new Date(), 'month');
  }

  function isLastMonth(date, currentDate) {
    return moment(date).isSame(moment(currentDate).subtract(1, 'months'), 'month');
  }

  function getCounts(leadTypeId) {
    if (!ranges) return { todayCount: 0, thisMonthCount: 0, lastMonthCount: 0 };
    const todayCount = ranges.filter((lead) => lead.leadTypeId === leadTypeId && isToday(lead.dateUpdated)).length;
    const thisMonthCount = ranges.filter((lead) => lead.leadTypeId === leadTypeId && isThisMonth(lead.dateUpdated)).length;
    const lastMonthCount = ranges.filter((lead) => lead.leadTypeId === leadTypeId && isLastMonth(lead.dateUpdated, new Date())).length;
    return { todayCount, thisMonthCount, lastMonthCount };
  }

  function getSourceCounts(leadSourceId) {
    if (!ranges) return { todayCount: 0, thisMonthCount: 0, lastMonthCount: 0 };
    const todayCount = ranges.filter((lead) => lead.leadSourceId === leadSourceId && isToday(lead.dateUpdated)).length;
    const thisMonthCount = ranges.filter((lead) => lead.leadSourceId === leadSourceId && isThisMonth(lead.dateUpdated)).length;
    const lastMonthCount = ranges.filter((lead) => lead.leadSourceId === leadSourceId && isLastMonth(lead.dateUpdated, new Date())).length;
    return { todayCount, thisMonthCount, lastMonthCount };
  }

  function getSpecialCounts(leadSourceId) {
    if (!ranges) return { todayCount: 0, thisMonthCount: 0, lastMonthCount: 0 };
    const todayCount = ranges.filter((lead) => lead.leadSourceId === leadSourceId && isToday(lead.dateUpdated) && lead.offerId > 0).length;
    const thisMonthCount = ranges.filter(
      (lead) => lead.leadSourceId === leadSourceId && isThisMonth(lead.dateUpdated) && lead.offerId > 0
    ).length;
    const lastMonthCount = ranges.filter(
      (lead) => lead.leadSourceId === leadSourceId && isLastMonth(lead.dateUpdated, new Date()) && lead.offerId > 0
    ).length;
    return { todayCount, thisMonthCount, lastMonthCount };
  }

  const getLineChartData = () => {
    const months = [];
    const leadCounts = [];
    let startDate = moment().subtract(18, 'months').startOf('month');

    while (startDate.isBefore(moment().endOf('month'))) {
      months.push(startDate.format('MM-YYYY'));
      leadCounts.push(ranges ? ranges.filter((lead) => moment(lead.dateUpdated).isSame(startDate, 'month')).length : 0);
      startDate.add(1, 'month');
    }

    return {
      labels: months,
      datasets: [
        {
          label: 'Lead Count',
          data: leadCounts,
          fill: false,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        }
      ]
    };
  };

  const handleToggle = (months) => {
    setFormattedDate(moment().subtract(months, 'months').startOf('month').format('YYYY-MM-DD'));
  };

  const handleReset = () => {
    setFormattedDate(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
  };

  const data = {
    labels: leadTypes.map((leadType) => leadType.name),
    datasets: [
      {
        label: 'Today',
        data: leadTypes.map((leadType) => getCounts(leadType.id).todayCount),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      },
      {
        label: 'This Month',
        data: leadTypes.map((leadType) => getCounts(leadType.id).thisMonthCount),
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1
      },
      {
        label: 'Last Month',
        data: leadTypes.map((leadType) => getCounts(leadType.id).lastMonthCount),
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1
      }
    ]
  };

  const colors = generateUniqueColors(leadTypes.length);

  const pieData = {
    labels: leadTypes.map((leadType) => leadType.name),
    datasets: [
      {
        data: leadTypes.map((leadType) => {
          switch (selectedPeriod) {
            case 'today':
              return getCounts(leadType.id).todayCount;
            case 'thisMonth':
              return getCounts(leadType.id).thisMonthCount;
            case 'lastMonth':
              return getCounts(leadType.id).lastMonthCount;
            default:
              return 0;
          }
        }),
        backgroundColor: colors,
        hoverBackgroundColor: colors.map((color) => {
          const [h, s, l] = color.match(/\d+/g);
          return `hsl(${h}, ${s}%, ${Math.max(0, l - 10)}%)`;
        })
      }
    ]
  };

  function generateUniqueColors(count) {
    const colors = [];
    const step = 360 / count;

    for (let i = 0; i < count; i++) {
      colors.push(`hsl(${i * step}, 70%, 60%)`);
    }

    return colors;
  }

  const GetGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) {
      return 'Good Morning';
    } else if (hour < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };

  const username = userSession?.name;

  // Calculate total leads for today
  const totalTodayLeads = ranges?.filter((lead) => isToday(lead.dateUpdated)).length || 0;

  return (
    <>
      {dealerId && (
        <div style={{ display: 'flex' }}>
          <Typography variant='h6' style={{ alignContent: 'space-around' }}>
            <GetGreeting /> {username}, Welcome to Dashboard!
          </Typography>
          <TotalLeadsBox>
            <Typography variant='h6'>Total Leads Today: {totalTodayLeads}</Typography>
          </TotalLeadsBox>
        </div>
      )}

      {dealerId ? (
        <Root>
          <Section>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Lead Type</TableCell>
                    <TableCell>Today</TableCell>
                    <TableCell>This Month</TableCell>
                    <TableCell>Last Month</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow index={0}>
                    <TableCell>Total Website Leads</TableCell>
                    <TableCell>{getSourceCounts(websiteSource).todayCount}</TableCell>
                    <TableCell>{getSourceCounts(websiteSource).thisMonthCount}</TableCell>
                    <TableCell>{getSourceCounts(websiteSource).lastMonthCount}</TableCell>
                  </StyledTableRow>
                  <StyledTableRow index={0}>
                    <TableCell>Specials</TableCell>
                    <TableCell>{getSpecialCounts(websiteSource).todayCount}</TableCell>
                    <TableCell>{getSpecialCounts(websiteSource).thisMonthCount}</TableCell>
                    <TableCell>{getSpecialCounts(websiteSource).lastMonthCount}</TableCell>
                  </StyledTableRow>
                  {leadTypes.map((leadType, index) => (
                    <StyledTableRow key={leadType.id} index={index + 1}>
                      <TableCell>{leadType.name}</TableCell>
                      <TableCell>{getCounts(leadType.id).todayCount}</TableCell>
                      <TableCell>{getCounts(leadType.id).thisMonthCount}</TableCell>
                      <TableCell>{getCounts(leadType.id).lastMonthCount}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Section>
          <Section>
            <Bar data={data} options={options} />
          </Section>
          <Section>
            <PieChartContainer>
              <PieChart>
                <Pie data={pieData} options={pieOptions} />
              </PieChart>
              <DropdownContainer>
                <FormControl fullWidth variant='standard'>
                  <InputLabel>Time Period</InputLabel>
                  <Select value={selectedPeriod} onChange={(e) => setSelectedPeriod(e.target.value)}>
                    <MenuItem value='today'>Today</MenuItem>
                    <MenuItem value='thisMonth'>This Month</MenuItem>
                    <MenuItem value='lastMonth'>Last Month</MenuItem>
                  </Select>
                </FormControl>
              </DropdownContainer>
            </PieChartContainer>
          </Section>
          <Section>
            <ButtonGroup variant='contained'>
              <Button onClick={() => handleToggle(3)}>Last 3 Months</Button>
              <Button onClick={() => handleToggle(6)}>Last 6 Months</Button>
              <Button onClick={() => handleToggle(12)}>Last 12 Months</Button>
              <Button onClick={() => handleToggle(18)}>Last 18 Months</Button>
              <Button onClick={handleReset}>Reset to last month</Button>
            </ButtonGroup>
            <Line data={getLineChartData()} options={options} />
          </Section>
        </Root>
      ) : (
        <Typography variant='h5'>Select a dealer to view stats</Typography>
      )}
    </>
  );
}
